import { SEO } from '@/components/layout/seo';
import { Breadcrumb } from '@/components/layout/breadcrumb';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Server, HardDrive, Network, Shield } from 'lucide-react';
import { Button } from '@/components/ui/button';

const nvrSolutions = [
  {
    title: 'Professional NVR Systems',
    description: 'Enterprise-grade network video recorders',
    icon: Server,
    href: '/surveillance/nvr/professional'
  },
  {
    title: 'Storage Solutions',
    description: 'Reliable storage options for surveillance footage',
    icon: HardDrive,
    href: '/surveillance/nvr/storage'
  },
  {
    title: 'Network Setup',
    description: 'Optimal network configuration for NVR systems',
    icon: Network,
    href: '/surveillance/nvr/network'
  },
  {
    title: 'Security Features',
    description: 'Advanced security and monitoring capabilities',
    icon: Shield,
    href: '/surveillance/nvr/security'
  }
];

export function NVRPage() {
  return (
    <>
      <SEO 
        title="NVR Solutions - Professional Surveillance Recording"
        description="Explore our range of Network Video Recorder (NVR) solutions for professional surveillance systems."
      />
      <div className="container py-8">
        <Breadcrumb items={[
          { title: 'Surveillance', href: '/surveillance' },
          { title: 'NVR', href: '/surveillance/nvr' }
        ]} />
        
        <div className="max-w-3xl mb-12">
          <h1 className="text-4xl font-bold tracking-tight mb-4">
            NVR Solutions
          </h1>
          <p className="text-xl text-muted-foreground">
            Professional Network Video Recorder solutions for reliable surveillance footage management.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {nvrSolutions.map((solution) => (
            <Card key={solution.href} className="hover:shadow-lg transition-shadow">
              <CardHeader>
                <solution.icon className="h-8 w-8 text-primary mb-4" />
                <CardTitle>{solution.title}</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-muted-foreground">{solution.description}</p>
                <Button variant="ghost" className="mt-4">
                  Learn More
                </Button>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </>
  );
}