import { Helmet } from 'react-helmet-async';
import { homeServerConfig } from '@/lib/constants';

interface SEOProps {
  title?: string;
  description?: string;
  canonical?: string;
  ogImage?: string;
}

export function SEO({ 
  title, 
  description = homeServerConfig.description,
  canonical = homeServerConfig.url,
  ogImage = homeServerConfig.ogImage 
}: SEOProps) {
  const fullTitle = title 
    ? `${title} | ${homeServerConfig.name}`
    : `${homeServerConfig.name} - Expert Home Server & NAS Solutions`;

  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonical} />
      
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:url" content={canonical} />
      <meta property="og:type" content="website" />
      
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImage} />
    </Helmet>
  );
}