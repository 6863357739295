import { Link } from 'react-router-dom';
import { ChevronRight, Home } from 'lucide-react';
import { cn } from '@/lib/utils';

interface BreadcrumbProps {
  items: {
    title: string;
    href: string;
    description?: string;
  }[];
}

export function Breadcrumb({ items }: BreadcrumbProps) {
  return (
    <nav aria-label="Breadcrumb" className="py-4">
      <ol className="flex flex-wrap items-center gap-2">
        <li className="flex items-center">
          <Link
            to="/"
            className="flex items-center text-muted-foreground hover:text-foreground transition-colors"
          >
            <Home className="h-4 w-4" />
            <span className="sr-only">Home</span>
          </Link>
        </li>
        {items.map((item, index) => (
          <li key={item.href} className="flex items-center">
            <ChevronRight className="h-4 w-4 text-muted-foreground mx-1" />
            <div className="group relative">
              <Link
                to={item.href}
                className={cn(
                  "hover:text-foreground transition-colors",
                  index === items.length - 1
                    ? "text-foreground font-medium"
                    : "text-muted-foreground"
                )}
              >
                {item.title}
              </Link>
              {item.description && (
                <div className="absolute left-0 top-full mt-1 hidden group-hover:block z-50">
                  <div className="bg-popover text-popover-foreground rounded-md shadow-md p-2 text-sm whitespace-nowrap">
                    {item.description}
                  </div>
                </div>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}