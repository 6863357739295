export const GA_CONFIG = {
  besthomeservers: {
    measurementId: 'G-7B6H71J16L',
  },
  surveillance: {
    measurementId: 'G-XRBXQEP767',
  },
} as const;

export const ANALYTICS_EVENTS = {
  REFERRAL_CLICK: 'referral_click',
  PRODUCT_VIEW: 'product_view',
  GUIDE_VIEW: 'guide_view',
  POLL_VOTE: 'poll_vote',
  DOWNLOAD: 'download',
  SEARCH: 'search',
} as const;

export const CONVERSION_EVENTS = {
  POLL_VOTE: {
    name: 'poll_vote',
    parameters: {
      engagement_time_msec: 1000,
      conversion: true,
    },
  },
  REFERRAL_CLICK: {
    name: 'referral_click',
    parameters: {
      conversion: true,
      value: 1,
    },
  },
  DOWNLOAD: {
    name: 'download',
    parameters: {
      conversion: true,
    },
  },
} as const;