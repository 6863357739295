import { config } from '@/lib/config';

type EventParams = Record<string, string | number | boolean>;

declare global {
  interface Window {
    gtag: (command: string, ...args: any[]) => void;
    dataLayer: any[];
  }
}

export function initializeAnalytics() {
  window.dataLayer = window.dataLayer || [];
  window.gtag = function gtag() {
    window.dataLayer.push(arguments);
  };

  window.gtag('js', new Date());
  window.gtag('config', config.analytics.gaId, {
    send_page_view: true,
    cookie_flags: 'SameSite=None;Secure',
  });
}

export function trackEvent(name: string, params?: EventParams) {
  if (window.gtag) {
    window.gtag('event', name, params);
  }
}

export function trackPageView(path: string) {
  trackEvent('page_view', { page_path: path });
}

export function trackSearch(term: string, resultCount: number) {
  trackEvent('search', {
    search_term: term,
    result_count: resultCount,
  });
}

export function trackOutboundLink(url: string, label: string) {
  trackEvent('click', {
    event_category: 'outbound',
    event_label: label,
    url: url,
  });
}