import { Server } from 'lucide-react';
import { config } from '@/lib/config';
import { CountdownTimer } from './countdown-timer';
import { NewsletterCard } from '@/components/newsletter/newsletter-card';
import { formatDate } from '@/lib/utils/date';

export function LaunchPage() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-background to-muted flex items-center justify-center p-4">
      <div className="max-w-4xl w-full space-y-12 text-center">
        <div className="space-y-4">
          <div className="flex justify-center mb-8">
            <Server className="h-16 w-16 text-primary" />
          </div>
          <h1 className="text-4xl md:text-5xl font-bold tracking-tight">
            {config.launch.title}
          </h1>
          <h2 className="text-2xl md:text-3xl font-semibold text-primary mb-4">
            {config.launch.headline}
          </h2>
          <p className="text-xl text-muted-foreground max-w-2xl mx-auto mb-4">
            {config.launch.description}
          </p>
          <p className="text-sm text-muted-foreground">
            Launching {formatDate(config.launch.date)}
          </p>
        </div>

        <CountdownTimer targetDate={config.launch.date} />
        
        <div className="max-w-md mx-auto">
          <NewsletterCard />
        </div>
      </div>
    </div>
  );
}