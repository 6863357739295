import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search } from 'lucide-react';
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { Button } from '@/components/ui/button';
import { navigationItems } from '@/lib/constants';
import { trackSearch } from '@/lib/analytics/gtag';

type SearchResult = {
  title: string;
  href: string;
  category: string;
};

export function CommandMenu() {
  const [open, setOpen] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
    };
    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);

  const generateSearchResults = (query: string): SearchResult[] => {
    if (!query) return [];

    const results: SearchResult[] = [];
    const searchQuery = query.toLowerCase();

    navigationItems.homeServer.forEach((item) => {
      if (item.subItems) {
        item.subItems.forEach((subItem) => {
          if (subItem.title.toLowerCase().includes(searchQuery)) {
            results.push({
              title: subItem.title,
              href: subItem.href,
              category: item.title,
            });
          }

          subItem.subItems?.forEach((childItem) => {
            if (childItem.title.toLowerCase().includes(searchQuery)) {
              results.push({
                title: childItem.title,
                href: childItem.href,
                category: subItem.title,
              });
            }
          });
        });
      }
    });

    return results;
  };

  const handleSearch = (value: string) => {
    const results = generateSearchResults(value);
    setSearchResults(results);
    
    // Track search in GA4
    if (value.length > 2) {
      trackSearch(value, 'Global Search', results.length);
    }
  };

  const handleSelect = (href: string) => {
    setOpen(false);
    navigate(href);
  };

  return (
    <>
      <Button
        variant="outline"
        className="relative h-9 w-9 p-0 xl:h-10 xl:w-60 xl:justify-start xl:px-3 xl:py-2"
        onClick={() => setOpen(true)}
      >
        <Search className="h-4 w-4 xl:mr-2" />
        <span className="hidden xl:inline-flex">Search...</span>
        <kbd className="pointer-events-none absolute right-1.5 top-2 hidden h-6 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 xl:flex">
          <span className="text-xs">⌘</span>K
        </kbd>
      </Button>
      <CommandDialog open={open} onOpenChange={setOpen}>
        <div className="sr-only">Search Dialog</div>
        <CommandInput 
          placeholder="Search for guides, hardware, software..." 
          onValueChange={handleSearch}
        />
        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>
          {searchResults.length > 0 && (
            <CommandGroup heading="Search Results">
              {searchResults.map((result, index) => (
                <CommandItem
                  key={index}
                  value={result.title}
                  onSelect={() => handleSelect(result.href)}
                >
                  <Search className="mr-2 h-4 w-4" />
                  <span>{result.title}</span>
                  <span className="ml-auto text-xs text-muted-foreground">
                    {result.category}
                  </span>
                </CommandItem>
              ))}
            </CommandGroup>
          )}
        </CommandList>
      </CommandDialog>
    </>
  );
}