import { SEO } from '@/components/layout/seo';
import { Breadcrumb } from '@/components/layout/breadcrumb';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Camera, Shield, Wifi, Settings } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { routes } from '@/lib/routes';

const categories = [
  {
    title: 'IP Cameras',
    description: 'Professional-grade IP cameras for reliable surveillance',
    icon: Camera,
    href: '/surveillance/cameras/ip'
  },
  {
    title: 'PTZ Cameras',
    description: 'Pan-tilt-zoom cameras for dynamic monitoring',
    icon: Settings,
    href: '/surveillance/cameras/ptz'
  },
  {
    title: 'Wireless Cameras',
    description: 'Easy-to-install wireless security solutions',
    icon: Wifi,
    href: '/surveillance/cameras/wireless'
  },
  {
    title: 'Security Features',
    description: 'Advanced security and monitoring capabilities',
    icon: Shield,
    href: '/surveillance/cameras/features'
  }
];

export function CamerasPage() {
  return (
    <>
      <SEO 
        title="Surveillance Cameras - Professional Security Solutions"
        description="Explore our range of professional surveillance cameras, from IP cameras to PTZ solutions."
      />
      <div className="container py-8">
        <Breadcrumb items={[
          { title: 'Surveillance', href: '/surveillance' },
          { title: 'Cameras', href: '/surveillance/cameras' }
        ]} />
        
        <div className="max-w-3xl mb-12">
          <h1 className="text-4xl font-bold tracking-tight mb-4">
            Surveillance Cameras
          </h1>
          <p className="text-xl text-muted-foreground">
            Find the perfect surveillance cameras for your security needs, from professional IP cameras to wireless solutions.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {categories.map((category) => (
            <Card key={category.href} className="hover:shadow-lg transition-shadow">
              <CardHeader>
                <category.icon className="h-8 w-8 text-primary mb-4" />
                <CardTitle>{category.title}</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-muted-foreground">{category.description}</p>
                <Button variant="ghost" className="mt-4">
                  Learn More
                </Button>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </>
  );
}