import { Link } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { 
  Server, 
  Shield, 
  Cloud, 
  Database, 
  Wifi, 
  Settings,
  MonitorPlay,
  Lock,
  ArrowRight
} from 'lucide-react';
import { routes } from '@/lib/routes';

const features = [
  {
    title: 'Centralized Storage',
    description: 'Keep all your data in one secure, accessible location with enterprise-grade storage solutions.',
    icon: Server,
    link: routes.hardware.storage.index
  },
  {
    title: 'Data Protection',
    description: 'Advanced RAID configurations and backup solutions to keep your data safe.',
    icon: Shield,
    link: routes.software.backup
  },
  {
    title: 'Cloud Integration',
    description: 'Seamlessly sync your data with popular cloud services while maintaining control.',
    icon: Cloud,
    link: '/guides/cloud-integration'
  },
  {
    title: 'Media Streaming',
    description: 'Stream your media collection to any device, anywhere with powerful media server capabilities.',
    icon: MonitorPlay,
    link: routes.software.mediaServers
  },
  {
    title: 'Remote Access',
    description: 'Access your files securely from anywhere in the world.',
    icon: Wifi,
    link: '/guides/remote-access'
  },
  {
    title: 'Easy Management',
    description: 'User-friendly interface for easy setup and maintenance of your home server.',
    icon: Settings,
    link: '/guides/server-management'
  },
  {
    title: 'Data Redundancy',
    description: 'Multiple backup options and redundancy features to prevent data loss.',
    icon: Database,
    link: '/guides/data-redundancy'
  },
  {
    title: 'Security First',
    description: 'Enterprise-grade encryption and security features to protect your data.',
    icon: Lock,
    link: '/guides/security'
  }
];

export function Features() {
  return (
    <section className="py-20 bg-muted/50">
      <div className="container">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold tracking-tight mb-4">
            Comprehensive Home Server Solutions
          </h2>
          <p className="text-xl text-muted-foreground max-w-2xl mx-auto">
            Discover the perfect balance of performance, reliability, and ease of use with our recommended solutions.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {features.map((feature) => (
            <Link key={feature.title} to={feature.link}>
              <Card className="h-full border-2 hover:border-primary transition-colors">
                <CardHeader>
                  <feature.icon className="h-10 w-10 text-primary mb-4" />
                  <CardTitle>{feature.title}</CardTitle>
                  <CardDescription>{feature.description}</CardDescription>
                </CardHeader>
                <CardContent>
                  <Button variant="ghost" size="sm" className="w-full group">
                    Learn More
                    <ArrowRight className="ml-2 h-4 w-4 group-hover:translate-x-1 transition-transform" />
                  </Button>
                </CardContent>
              </Card>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}