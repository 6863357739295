import { Link } from 'react-router-dom';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";
import { featuredBuilds } from "@/lib/constants";
import { trackProductView } from "@/lib/analytics/gtag";
import { useEffect } from "react";

export function FeaturedBuilds() {
  useEffect(() => {
    featuredBuilds.forEach(build => {
      trackProductView(build.title, 'Featured Builds', 0);
    });
  }, []);

  return (
    <section className="py-16 bg-background">
      <div className="container">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold tracking-tight mb-4">Featured Home Server Builds</h2>
          <p className="text-xl text-muted-foreground">
            Discover our most popular and tested home server configurations
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {featuredBuilds.map((build, index) => (
            <Link key={index} to={`/builds/${build.title.toLowerCase().replace(/\s+/g, '-')}`}>
              <Card className="h-full overflow-hidden hover:shadow-lg transition-shadow">
                <CardHeader className="p-0">
                  <div className="aspect-video relative overflow-hidden">
                    <img
                      src={build.image}
                      alt={build.title}
                      className="object-cover w-full h-full transform hover:scale-105 transition-transform duration-300"
                    />
                  </div>
                </CardHeader>
                <CardContent className="p-6">
                  <CardTitle className="mb-2">{build.title}</CardTitle>
                  <CardDescription className="mb-4">{build.description}</CardDescription>
                  <div className="space-y-2 text-sm mb-4">
                    <p><strong>CPU:</strong> {build.specs.cpu}</p>
                    <p><strong>RAM:</strong> {build.specs.ram}</p>
                    <p><strong>Storage:</strong> {build.specs.storage}</p>
                    <p><strong>OS:</strong> {build.specs.os}</p>
                  </div>
                  <Button variant="ghost" size="sm" className="w-full group">
                    View Build Details
                    <ArrowRight className="ml-2 h-4 w-4 group-hover:translate-x-1 transition-transform" />
                  </Button>
                </CardContent>
              </Card>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}