import { Routes, Route, useLocation } from 'react-router-dom';
import { Layout } from '@/components/layout';
import { LaunchGate } from '@/components/launch/launch-gate';
import { trackPageView } from '@/lib/analytics';
import { config } from '@/lib/config';
import { useEffect } from 'react';

// Pages
import { HomePage } from '@/pages/home-server';
import { HardwarePage } from '@/pages/home-server/hardware';
import { SoftwarePage } from '@/pages/home-server/software';
import { SurveillancePage } from '@/pages/surveillance';
import { CamerasPage } from '@/pages/surveillance/cameras';
import { NVRPage } from '@/pages/surveillance/nvr';
import { GuidesPage } from '@/pages/guides';

export default function App() {
  const location = useLocation();
  const isSurveillance = config.env === 'surveillance';
  
  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  return (
    <LaunchGate>
      <Layout>
        <Routes>
          {isSurveillance ? (
            <>
              <Route index element={<SurveillancePage />} />
              <Route path="cameras/*" element={<CamerasPage />} />
              <Route path="nvr/*" element={<NVRPage />} />
            </>
          ) : (
            <>
              <Route index element={<HomePage />} />
              <Route path="hardware/*" element={<HardwarePage />} />
              <Route path="software/*" element={<SoftwarePage />} />
            </>
          )}
          <Route path="guides/*" element={<GuidesPage />} />
        </Routes>
      </Layout>
    </LaunchGate>
  );
}