import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X, Server } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { NavMenu } from './nav-menu';
import { MobileNav } from './mobile-nav';
import { CommandMenu } from '@/components/search/command-menu';
import { cn } from '@/lib/utils';

export function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header 
      className={cn(
        "sticky top-0 z-50 w-full border-b transition-all duration-200",
        isScrolled 
          ? "bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60"
          : "bg-background"
      )}
    >
      <div className="container flex h-16 items-center justify-between">
        <Link 
          to="/" 
          className="flex items-center space-x-2"
          aria-label="BestHomeServers Home"
        >
          <Server className="h-6 w-6" aria-hidden="true" />
          <span className="hidden font-bold sm:inline-block">BestHomeServers</span>
        </Link>

        <NavMenu />
        
        <div className="flex items-center space-x-4">
          <CommandMenu />
          <Button 
            variant="ghost" 
            size="icon" 
            className="md:hidden"
            onClick={() => setIsOpen(!isOpen)}
            aria-expanded={isOpen}
            aria-controls="mobile-nav"
            aria-label={isOpen ? "Close menu" : "Open menu"}
          >
            {isOpen ? (
              <X className="h-6 w-6" aria-hidden="true" />
            ) : (
              <Menu className="h-6 w-6" aria-hidden="true" />
            )}
          </Button>
        </div>
      </div>

      <MobileNav 
        isOpen={isOpen} 
        setIsOpen={setIsOpen} 
        id="mobile-nav"
        aria-hidden={!isOpen}
      />
    </header>
  );
}