import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { ArrowRight } from 'lucide-react';

const steps = [
  {
    title: 'Plan Your Setup',
    description: 'Determine camera placement and coverage areas',
    tips: [
      'Identify key monitoring areas',
      'Consider lighting conditions',
      'Plan cable routing'
    ]
  },
  {
    title: 'Choose Your Equipment',
    description: 'Select cameras and NVR system',
    tips: [
      'Match cameras to environment',
      'Calculate storage needs',
      'Consider future expansion'
    ]
  },
  {
    title: 'Professional Installation',
    description: 'Ensure proper setup and configuration',
    tips: [
      'Follow safety guidelines',
      'Test network connectivity',
      'Configure remote access'
    ]
  }
];

export function SetupGuide() {
  return (
    <section className="py-16 bg-muted/30">
      <div className="container">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold tracking-tight mb-4">
            Setup Guide
          </h2>
          <p className="text-xl text-muted-foreground max-w-2xl mx-auto">
            Follow our professional installation guide for optimal results
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {steps.map((step, index) => (
            <Card key={index} className="hover:shadow-lg transition-shadow">
              <CardContent className="p-6">
                <div className="text-4xl font-bold text-primary mb-4">
                  {index + 1}
                </div>
                <h3 className="text-xl font-bold mb-2">{step.title}</h3>
                <p className="text-muted-foreground mb-4">{step.description}</p>
                <ul className="space-y-2 mb-4">
                  {step.tips.map((tip) => (
                    <li key={tip} className="text-sm text-muted-foreground">
                      • {tip}
                    </li>
                  ))}
                </ul>
                <Button variant="outline" className="w-full">
                  Learn More
                  <ArrowRight className="ml-2 h-4 w-4" />
                </Button>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
}