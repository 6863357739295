import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { ArrowRight, ArrowRightCircle } from 'lucide-react';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { cn } from '@/lib/utils';
import useEmblaCarousel, { type EmblaCarouselType } from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';

const slides = [
  {
    title: "Build Your Perfect Home Server",
    description: "From NAS solutions to virtualization, discover the ideal setup for your needs.",
    image: "https://images.unsplash.com/photo-1600267204091-5c1ab8b10c02?auto=format&fit=crop&q=80",
    cta: {
      text: "Explore Solutions",
      link: "/hardware"
    },
    features: [
      "Customized Builds",
      "Expert Guidance",
      "Performance Optimized"
    ]
  },
  {
    title: "Professional Storage Solutions",
    description: "Enterprise-grade storage configurations for home and small business use.",
    image: "https://images.unsplash.com/photo-1601386222674-715d1c72f518?auto=format&fit=crop&q=80",
    cta: {
      text: "View Storage Options",
      link: "/hardware/storage"
    },
    features: [
      "Data Protection",
      "Scalable Solutions",
      "Reliable Performance"
    ]
  },
  {
    title: "Advanced Home Lab Setups",
    description: "Take your home lab to the next level with professional-grade configurations.",
    image: "https://images.unsplash.com/photo-1589652717521-10c0d092dea9?auto=format&fit=crop&q=80",
    cta: {
      text: "Explore Home Labs",
      link: "/homelabbers-corner"
    },
    features: [
      "Virtualization Ready",
      "High Performance",
      "Expandable Design"
    ]
  }
];

const autoplayOptions = {
  delay: 5000,
  rootNode: (emblaRoot: HTMLElement) => emblaRoot.parentElement,
};

export function HeroSlider() {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const [emblaRef, emblaApi] = useEmblaCarousel(
    { 
      loop: true,
      align: 'start',
      skipSnaps: false,
    },
    [Autoplay(autoplayOptions)]
  );

  useEffect(() => {
    if (!emblaApi) return;

    const onSelect = () => {
      setActiveIndex(emblaApi.selectedScrollSnap());
    };

    emblaApi.on('select', onSelect);
    
    // Initial index
    setActiveIndex(emblaApi.selectedScrollSnap());

    return () => {
      emblaApi.off('select', onSelect);
    };
  }, [emblaApi]);

  const scrollTo = (index: number) => {
    if (emblaApi) {
      emblaApi.scrollTo(index);
    }
  };

  return (
    <section className="relative bg-background overflow-hidden">
      <div className="w-full" ref={emblaRef}>
        <div className="flex">
          {slides.map((slide, index) => (
            <div 
              key={index} 
              className="flex-[0_0_100%] min-w-0"
              role="group"
              aria-roledescription="slide"
              aria-label={`${index + 1} of ${slides.length}`}
            >
              <div className="container grid grid-cols-1 lg:grid-cols-2 gap-8 py-20">
                <div className="flex flex-col justify-center">
                  <h1 className="text-4xl md:text-5xl font-bold tracking-tight mb-6">
                    {slide.title}
                  </h1>
                  <p className="text-xl text-muted-foreground mb-8">
                    {slide.description}
                  </p>
                  <div className="flex flex-wrap gap-4 mb-8">
                    <Button 
                      size="lg"
                      onClick={() => navigate(slide.cta.link)}
                    >
                      {slide.cta.text}
                      <ArrowRight className="ml-2 h-4 w-4" />
                    </Button>
                  </div>
                  <div className="grid grid-cols-3 gap-4">
                    {slide.features.map((feature, i) => (
                      <div key={i} className="flex items-center space-x-2">
                        <ArrowRightCircle className="h-5 w-5 text-primary" />
                        <span className="text-sm">{feature}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="relative">
                  <div className="aspect-[4/3] rounded-lg overflow-hidden shadow-2xl">
                    <img
                      src={slide.image}
                      alt={slide.title}
                      className="object-cover w-full h-full transform hover:scale-105 transition-transform duration-300"
                    />
                    <div className="absolute inset-0 bg-gradient-to-tr from-background/80 to-transparent rounded-lg" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Navigation Dots */}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => scrollTo(index)}
            className={cn(
              "w-2 h-2 rounded-full transition-all duration-300",
              activeIndex === index 
                ? "bg-primary w-8" 
                : "bg-primary/30 hover:bg-primary/50"
            )}
            aria-label={`Go to slide ${index + 1}`}
            aria-current={activeIndex === index ? 'true' : 'false'}
          />
        ))}
      </div>

      <button
        onClick={() => emblaApi?.scrollPrev()}
        className="absolute left-4 top-1/2 -translate-y-1/2 hidden md:flex h-10 w-10 items-center justify-center rounded-full bg-background/80 shadow-md hover:bg-background"
        aria-label="Previous slide"
      >
        <ArrowRight className="h-4 w-4 rotate-180" />
      </button>
      
      <button
        onClick={() => emblaApi?.scrollNext()}
        className="absolute right-4 top-1/2 -translate-y-1/2 hidden md:flex h-10 w-10 items-center justify-center rounded-full bg-background/80 shadow-md hover:bg-background"
        aria-label="Next slide"
      >
        <ArrowRight className="h-4 w-4" />
      </button>
    </section>
  );
}