import { SEO } from '@/components/layout/seo';
import { HeroSlider } from '@/components/sections/surveillance/hero-slider';
import { Features } from '@/components/sections/surveillance/features';
import { CameraComparison } from '@/components/sections/surveillance/camera-comparison';
import { SetupGuide } from '@/components/sections/surveillance/setup-guide';

export function SurveillancePage() {
  return (
    <>
      <SEO
        title="Home Surveillance Solutions - Expert Camera & NVR Guide"
        description="Professional guidance on home surveillance systems, security cameras, and NVR solutions. Find the perfect setup for your security needs."
      />
      <div className="min-h-screen">
        <HeroSlider />
        <Features />
        <CameraComparison />
        <SetupGuide />
      </div>
    </>
  );
}