import { useState } from 'react';
import { Link } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '@/components/ui/navigation-menu';
import { Card, CardContent } from '@/components/ui/card';
import { navigationItems } from '@/lib/constants';

export function NavMenu() {
  const [activeItem, setActiveItem] = useState<string | null>(null);

  return (
    <NavigationMenu className="hidden md:flex">
      <NavigationMenuList>
        {navigationItems.homeServer.map((item) => (
          <NavigationMenuItem key={item.href}>
            {item.subItems ? (
              <>
                <NavigationMenuTrigger
                  onMouseEnter={() => setActiveItem(item.title)}
                  className="h-10"
                >
                  {item.title}
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                  <div className="grid w-[800px] gap-3 p-4 md:grid-cols-2">
                    <div className="col-span-1">
                      {item.subItems.map((subItem) => (
                        <div key={subItem.href} className="mb-4">
                          <div className="mb-2">
                            <Button
                              variant="link"
                              className="h-auto p-0 text-base font-medium"
                              asChild
                            >
                              <Link to={subItem.href}>{subItem.title}</Link>
                            </Button>
                            <p className="text-sm text-muted-foreground mt-1">
                              {getSubmenuDescription(subItem.title)}
                            </p>
                          </div>
                          {subItem.subItems && (
                            <ul className="grid gap-2 mt-2">
                              {subItem.subItems.map((childItem) => (
                                <li key={childItem.href}>
                                  <Button
                                    variant="ghost"
                                    size="sm"
                                    className="w-full justify-start text-sm h-auto py-1"
                                    asChild
                                  >
                                    <Link to={childItem.href}>{childItem.title}</Link>
                                  </Button>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="col-span-1">
                      <Card className="h-full">
                        <CardContent className="p-4">
                          <div className="aspect-video relative rounded-lg overflow-hidden mb-4">
                            <img
                              src={getMenuImage(item.title)}
                              alt={`${item.title} overview`}
                              className="object-cover w-full h-full"
                            />
                          </div>
                          <div className="space-y-2">
                            <h4 className="font-medium">Quick Guide</h4>
                            <p className="text-sm text-muted-foreground">
                              {getQuickTip(item.title)}
                            </p>
                            <Button variant="outline" size="sm" asChild>
                              <Link to={`${item.href}/guide`}>Learn More</Link>
                            </Button>
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  </div>
                </NavigationMenuContent>
              </>
            ) : (
              <Button variant="ghost" className="h-10" asChild>
                <Link to={item.href}>{item.title}</Link>
              </Button>
            )}
          </NavigationMenuItem>
        ))}
      </NavigationMenuList>
    </NavigationMenu>
  );
}

function getMenuImage(category: string): string {
  const images = {
    Hardware: 'https://images.unsplash.com/photo-1591799264318-7e6ef8ddb7ea?auto=format&fit=crop&q=80',
    Software: 'https://images.unsplash.com/photo-1558494949-ef010cbdcc31?auto=format&fit=crop&q=80',
    Guides: 'https://images.unsplash.com/photo-1516321318423-f06f85e504b3?auto=format&fit=crop&q=80',
  };
  return images[category as keyof typeof images] || images.Hardware;
}

function getQuickTip(category: string): string {
  const tips = {
    Hardware: 'Choose the right hardware based on your storage needs, processing requirements, and form factor preferences.',
    Software: 'Start with a reliable operating system and add essential services like backup, media streaming, and virtualization.',
    Guides: 'Follow our step-by-step tutorials to set up and optimize your home server environment.',
  };
  return tips[category as keyof typeof tips] || '';
}

function getSubmenuDescription(title: string): string {
  const descriptions = {
    'Form Factors': 'Compare desktop, rackmount, and tower server options',
    'Storage Solutions': 'Explore HDDs, SSDs, and RAID configurations',
    'Operating Systems': 'Find the perfect OS for your server needs',
    'Media Servers': 'Stream your media collection effortlessly',
    'Getting Started': 'Begin your home server journey here',
    'Advanced Setup': 'Take your server to the next level',
  };
  return descriptions[title as keyof typeof descriptions] || '';
}