import { GA_CONFIG, ANALYTICS_EVENTS, CONVERSION_EVENTS } from './config';

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
    dataLayer: any[];
  }
}

type WebsiteType = 'besthomeservers' | 'surveillance';

export function initializeGA(website: WebsiteType) {
  const config = GA_CONFIG[website];
  
  // Initialize dataLayer
  window.dataLayer = window.dataLayer || [];
  window.gtag = function gtag() {
    window.dataLayer.push(arguments);
  };
  
  // Initial gtag setup
  window.gtag('js', new Date());
  window.gtag('config', config.measurementId, {
    send_page_view: true,
    cookie_flags: 'SameSite=None;Secure'
  });
}

export function trackEvent(
  eventName: string,
  eventParams?: Record<string, any>
) {
  if (window.gtag) {
    window.gtag('event', eventName, eventParams);
  }
}

export function trackReferralClick(
  productName: string,
  url: string,
  category: string
) {
  trackEvent(ANALYTICS_EVENTS.REFERRAL_CLICK, {
    ...CONVERSION_EVENTS.REFERRAL_CLICK.parameters,
    product_name: productName,
    destination_url: url,
    category: category
  });
}

export function trackPollVote(
  pollQuestion: string,
  selectedOption: string
) {
  trackEvent(ANALYTICS_EVENTS.POLL_VOTE, {
    ...CONVERSION_EVENTS.POLL_VOTE.parameters,
    poll_question: pollQuestion,
    selected_option: selectedOption
  });
}

export function trackProductView(
  productName: string,
  category: string,
  value: number
) {
  trackEvent(ANALYTICS_EVENTS.PRODUCT_VIEW, {
    product_name: productName,
    category: category,
    value: value
  });
}

export function trackSearch(
  searchTerm: string,
  searchType: string,
  resultCount: number
) {
  trackEvent(ANALYTICS_EVENTS.SEARCH, {
    search_term: searchTerm,
    search_type: searchType,
    result_count: resultCount
  });
}

export function trackDownload(
  fileName: string,
  fileType: string
) {
  trackEvent(ANALYTICS_EVENTS.DOWNLOAD, {
    ...CONVERSION_EVENTS.DOWNLOAD.parameters,
    file_name: fileName,
    file_type: fileType
  });
}