import { SEO } from '@/components/layout/seo';
import { Breadcrumb } from '@/components/layout/breadcrumb';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { 
  MonitorPlay, 
  Database, 
  HardDrive, 
  Server,
  ArrowRight
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import { routes } from '@/lib/routes';

const categories = [
  {
    title: 'Operating Systems',
    description: 'Find the perfect OS for your home server, from TrueNAS to Unraid.',
    icon: HardDrive,
    href: routes.software.os
  },
  {
    title: 'Media Servers',
    description: 'Stream your media collection with Plex, Emby, or Jellyfin.',
    icon: MonitorPlay,
    href: routes.software.mediaServers
  },
  {
    title: 'Backup Solutions',
    description: 'Protect your data with reliable backup software solutions.',
    icon: Database,
    href: routes.software.backup
  },
  {
    title: 'Virtualization',
    description: 'Run multiple services with Docker, Proxmox, or VMware.',
    icon: Server,
    href: routes.software.virtualization
  }
];

export function SoftwarePage() {
  return (
    <>
      <SEO 
        title="Software Solutions"
        description="Essential software solutions for your home server, from operating systems to media servers and backup solutions."
      />
      <div className="container py-8">
        <Breadcrumb items={[{ title: 'Software', href: routes.software.index }]} />
        
        <div className="max-w-3xl mb-12">
          <h1 className="text-4xl font-bold tracking-tight mb-4">
            Software Solutions
          </h1>
          <p className="text-xl text-muted-foreground">
            Discover the essential software solutions to power your home server.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {categories.map((category) => (
            <Card key={category.href} className="hover:shadow-lg transition-shadow">
              <CardHeader>
                <category.icon className="h-8 w-8 text-primary mb-4" />
                <CardTitle>{category.title}</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-muted-foreground">{category.description}</p>
                <Button variant="ghost" className="mt-4">
                  Learn More
                  <ArrowRight className="ml-2 h-4 w-4" />
                </Button>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </>
  );
}