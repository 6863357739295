import { SEO } from '@/components/layout/seo';
import { Breadcrumb } from '@/components/layout/breadcrumb';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { 
  BookOpen, 
  Lightbulb,
  WrenchIcon,
  CheckCircle2,
  ArrowRight
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import { routes } from '@/lib/routes';

const categories = [
  {
    title: "Beginner's Guide",
    description: 'Start your home server journey with our comprehensive guides.',
    icon: BookOpen,
    href: routes.guides.beginners
  },
  {
    title: 'Advanced Tutorials',
    description: 'Deep dive into advanced home server configurations.',
    icon: Lightbulb,
    href: routes.guides.advanced
  },
  {
    title: 'Troubleshooting',
    description: 'Solutions for common home server issues and problems.',
    icon: WrenchIcon,
    href: routes.guides.troubleshooting
  },
  {
    title: 'Best Practices',
    description: 'Learn the best practices for maintaining your home server.',
    icon: CheckCircle2,
    href: routes.guides.bestPractices
  }
];

export function GuidesPage() {
  return (
    <>
      <SEO 
        title="Home Server Guides & Tutorials"
        description="Comprehensive guides and tutorials for setting up and maintaining your home server."
      />
      <div className="container py-8">
        <Breadcrumb items={[{ title: 'Guides', href: routes.guides.index }]} />
        
        <div className="max-w-3xl mb-12">
          <h1 className="text-4xl font-bold tracking-tight mb-4">
            Home Server Guides
          </h1>
          <p className="text-xl text-muted-foreground">
            From beginner tutorials to advanced configurations, find the guidance you need for your home server journey.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
          {categories.map((category) => (
            <Card key={category.href} className="hover:shadow-lg transition-shadow">
              <CardHeader>
                <category.icon className="h-8 w-8 text-primary mb-4" />
                <CardTitle className="flex items-center justify-between">
                  {category.title}
                  <Button variant="ghost" size="sm">
                    Explore
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </Button>
                </CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-muted-foreground">{category.description}</p>
              </CardContent>
            </Card>
          ))}
        </div>

        <section className="bg-muted/30 rounded-lg p-8 mb-12">
          <h2 className="text-2xl font-bold mb-4">Featured Guides</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <a href="/guides/first-home-server" className="group block p-4 bg-background rounded-lg hover:shadow-md transition-shadow">
              <h3 className="font-semibold group-hover:text-primary transition-colors">Building Your First Home Server</h3>
              <p className="text-sm text-muted-foreground">Complete guide for beginners</p>
            </a>
            <a href="/guides/nas-setup" className="group block p-4 bg-background rounded-lg hover:shadow-md transition-shadow">
              <h3 className="font-semibold group-hover:text-primary transition-colors">NAS Setup Guide</h3>
              <p className="text-sm text-muted-foreground">Setting up your first NAS</p>
            </a>
            <a href="/guides/server-security" className="group block p-4 bg-background rounded-lg hover:shadow-md transition-shadow">
              <h3 className="font-semibold group-hover:text-primary transition-colors">Server Security Essentials</h3>
              <p className="text-sm text-muted-foreground">Securing your home server</p>
            </a>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-bold mb-4">Latest Tutorials</h2>
          <div className="grid grid-cols-1 gap-4">
            {[1, 2, 3].map((_, index) => (
              <Card key={index} className="hover:shadow-md transition-shadow">
                <CardContent className="flex items-center justify-between p-4">
                  <div>
                    <h3 className="font-semibold mb-1">Docker Container Management</h3>
                    <p className="text-sm text-muted-foreground">Learn how to manage Docker containers effectively</p>
                  </div>
                  <Button variant="ghost" size="sm">
                    Read More
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </Button>
                </CardContent>
              </Card>
            ))}
          </div>
        </section>
      </div>
    </>
  );
}