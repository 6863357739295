import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { ArrowRight } from 'lucide-react';

const cameras = [
  {
    name: 'Synology BC500',
    type: 'Professional IP Camera',
    features: [
      '5MP Resolution',
      'Smart Motion Detection',
      'Night Vision',
      'IP67 Weatherproof'
    ],
    idealFor: 'Outdoor surveillance',
    price: '$299'
  },
  {
    name: 'Synology CC500',
    type: 'Compact Cube Camera',
    features: [
      '5MP Resolution',
      'Two-way Audio',
      'Wide-angle Lens',
      'Person Detection'
    ],
    idealFor: 'Indoor monitoring',
    price: '$199'
  }
];

export function CameraComparison() {
  return (
    <section className="py-16 bg-background">
      <div className="container">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold tracking-tight mb-4">
            Featured Security Cameras
          </h2>
          <p className="text-xl text-muted-foreground">
            Professional-grade surveillance cameras for every need
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {cameras.map((camera) => (
            <Card key={camera.name} className="hover:shadow-lg transition-shadow">
              <CardHeader>
                <CardTitle>{camera.name}</CardTitle>
                <p className="text-sm text-muted-foreground">{camera.type}</p>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <div>
                    <h3 className="font-semibold mb-2">Key Features</h3>
                    <ul className="space-y-1">
                      {camera.features.map((feature) => (
                        <li key={feature} className="text-sm text-muted-foreground">
                          • {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <p className="text-sm"><strong>Best For:</strong> {camera.idealFor}</p>
                    <p className="text-lg font-bold mt-2">{camera.price}</p>
                  </div>
                  <Button className="w-full">
                    Learn More
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </Button>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
}