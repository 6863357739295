import { ReactNode } from 'react';
import { LaunchPage } from './launch-page';
import { config } from '@/lib/config';
import { isBeforeDate } from '@/lib/utils/date';

interface LaunchGateProps {
  children: ReactNode;
}

export function LaunchGate({ children }: LaunchGateProps) {
  const now = new Date();
  const launchDate = config.launch.date;
  
  // Show launch page if current date is before launch date
  if (isBeforeDate(now, launchDate) && !config.launch.bypass) {
    return <LaunchPage />;
  }

  // Show main content if launch date has passed or bypass is enabled
  return <>{children}</>;
}