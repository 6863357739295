import { SEO } from '@/components/layout/seo';
import { HeroSlider } from '@/components/sections/hero-slider';
import { Features } from '@/components/sections/features';
import { FeaturedBuilds } from '@/components/sections/featured-builds';
import { QuickLinks } from '@/components/sections/quick-links';
import { CommunityPolls } from '@/components/sections/community-polls';

export function HomePage() {
  return (
    <>
      <SEO />
      <HeroSlider />
      <Features />
      <FeaturedBuilds />
      <CommunityPolls />
      <QuickLinks />
    </>
  );
}