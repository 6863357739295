import { ReactNode } from 'react';
import { LayoutRoot } from './layout-root';
import { LayoutMain } from './layout-main';
import { Header } from './header';
import { Footer } from './footer';

interface LayoutProps {
  children: ReactNode;
}

export function Layout({ children }: LayoutProps) {
  return (
    <LayoutRoot>
      <Header />
      <LayoutMain>
        {children}
      </LayoutMain>
      <Footer />
    </LayoutRoot>
  );
}

export { LayoutRoot, LayoutMain };