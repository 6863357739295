import { SEO } from '@/components/layout/seo';
import { Breadcrumb } from '@/components/layout/breadcrumb';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { HardDrive, Cpu, Network, Wrench } from 'lucide-react';
import { routes } from '@/lib/routes';

const categories = [
  {
    title: 'Pre-built Servers',
    description: 'Complete server solutions ready for deployment',
    icon: HardDrive,
    href: routes.hardware.preBuilt
  },
  {
    title: 'DIY Builds',
    description: 'Custom server build guides and components',
    icon: Wrench,
    href: routes.hardware.diyBuilds
  },
  {
    title: 'Components',
    description: 'Individual server components and upgrades',
    icon: Cpu,
    href: routes.hardware.components
  },
  {
    title: 'Network Equipment',
    description: 'Networking hardware for your home server',
    icon: Network,
    href: routes.hardware.network
  }
];

export function HardwarePage() {
  return (
    <>
      <SEO 
        title="Hardware Solutions"
        description="Explore our comprehensive hardware solutions for home servers, from pre-built NAS to DIY builds."
      />
      <div className="container py-8">
        <Breadcrumb items={[{ title: 'Hardware', href: routes.hardware.index }]} />
        
        <div className="max-w-3xl mb-12">
          <h1 className="text-4xl font-bold tracking-tight mb-4">
            Hardware Solutions
          </h1>
          <p className="text-xl text-muted-foreground">
            Find the perfect hardware solution for your home server needs, from pre-built NAS systems to custom DIY builds.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {categories.map((category) => (
            <Card key={category.href} className="hover:shadow-lg transition-shadow">
              <CardHeader>
                <category.icon className="h-8 w-8 text-primary mb-4" />
                <CardTitle>{category.title}</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-muted-foreground">{category.description}</p>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </>
  );
}