import { get, set } from 'idb-keyval';
import { type NewsletterSubscription } from '@/lib/types';
import { newsletterSchema } from '@/lib/types';

const NEWSLETTER_STORE_KEY = 'newsletter_subscriptions';

export async function subscribeToNewsletter(email: string, interests?: string[]): Promise<{ success: boolean; message: string }> {
  try {
    // Validate email and create subscription object
    const subscription = newsletterSchema.parse({
      email,
      interests,
      subscribeDate: new Date().toISOString()
    });

    // Get existing subscriptions
    const existingSubscriptions: NewsletterSubscription[] = await get(NEWSLETTER_STORE_KEY) || [];
    
    // Check for duplicate email
    if (existingSubscriptions.some(sub => sub.email === email)) {
      return {
        success: false,
        message: 'This email is already subscribed to our newsletter'
      };
    }

    // Add new subscription
    const updatedSubscriptions = [...existingSubscriptions, subscription];
    await set(NEWSLETTER_STORE_KEY, updatedSubscriptions);

    return {
      success: true,
      message: 'Successfully subscribed to newsletter'
    };
  } catch (error) {
    console.error('Newsletter subscription error:', error);
    return {
      success: false,
      message: error instanceof Error ? error.message : 'Failed to subscribe to newsletter'
    };
  }
}

export async function getSubscriptions(): Promise<NewsletterSubscription[]> {
  return await get(NEWSLETTER_STORE_KEY) || [];
}