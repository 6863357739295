import { z } from 'zod';

const envSchema = z.object({
  VITE_DOMAIN: z.string(),
  VITE_GA_ID: z.string(),
  VITE_LAUNCH_DATE: z.string().transform(str => new Date(str)),
});

function validateEnv() {
  const parsed = envSchema.safeParse({
    VITE_DOMAIN: import.meta.env.VITE_DOMAIN,
    VITE_GA_ID: import.meta.env.VITE_GA_ID,
    VITE_LAUNCH_DATE: import.meta.env.VITE_LAUNCH_DATE,
  });

  if (!parsed.success) {
    console.error('❌ Invalid environment variables:', parsed.error.flatten().fieldErrors);
    throw new Error('Invalid environment variables');
  }

  return parsed.data;
}

const env = validateEnv();

export const config = {
  env: env.VITE_DOMAIN.includes('surveillance') ? 'surveillance' : 'homeserver',
  domain: env.VITE_DOMAIN,
  analytics: {
    gaId: env.VITE_GA_ID,
  },
  launch: {
    date: env.VITE_LAUNCH_DATE,
  },
} as const;