import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Camera, Shield, Bell, Wifi, Server, Lock } from 'lucide-react';

const features = [
  {
    title: 'HD Surveillance',
    description: 'Crystal clear 4K resolution with night vision capabilities',
    icon: Camera
  },
  {
    title: 'Smart Detection',
    description: 'AI-powered motion detection and object recognition',
    icon: Shield
  },
  {
    title: 'Instant Alerts',
    description: 'Real-time notifications on your mobile device',
    icon: Bell
  },
  {
    title: 'Remote Access',
    description: 'View your cameras from anywhere, anytime',
    icon: Wifi
  },
  {
    title: 'Secure Storage',
    description: 'Reliable NVR systems with redundant storage',
    icon: Server
  },
  {
    title: 'Data Protection',
    description: 'End-to-end encryption for your security footage',
    icon: Lock
  }
];

export function Features() {
  return (
    <section className="py-20 bg-muted/50">
      <div className="container">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold tracking-tight mb-4">
            Complete Surveillance Solutions
          </h2>
          <p className="text-xl text-muted-foreground max-w-2xl mx-auto">
            Professional-grade security features to protect what matters most.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {features.map((feature) => (
            <Card key={feature.title} className="border-2 hover:border-primary transition-colors">
              <CardHeader>
                <feature.icon className="h-10 w-10 text-primary mb-4" />
                <CardTitle>{feature.title}</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-muted-foreground">{feature.description}</p>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
}