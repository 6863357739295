import { useState, useEffect } from 'react';
import { formatTimeLeft } from '@/lib/utils/date';

export function useLaunchTimer(targetDate: Date) {
  const [timeLeft, setTimeLeft] = useState(() => formatTimeLeft(targetDate));

  useEffect(() => {
    const calculateTimeLeft = () => {
      setTimeLeft(formatTimeLeft(targetDate));
    };

    // Calculate immediately
    calculateTimeLeft();
    
    // Update every second
    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  return timeLeft;
}