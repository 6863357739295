import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { newsletterSchema } from '@/lib/types';
import { subscribeToNewsletter } from '@/lib/api/newsletter';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Checkbox } from '@/components/ui/checkbox';
import { useToast } from '@/hooks/use-toast';
import { Mail } from 'lucide-react';

const interests = [
  { id: 'home-servers', label: 'Home Servers' },
  { id: 'nas', label: 'NAS Solutions' },
  { id: 'surveillance', label: 'Surveillance' }
];

export function NewsletterForm() {
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: zodResolver(newsletterSchema)
  });

  const onSubmit = async (data: { email: string; interests?: string[] }) => {
    setLoading(true);
    try {
      const result = await subscribeToNewsletter(data.email, data.interests);
      
      if (result.success) {
        toast({
          title: "Success!",
          description: "Thank you for subscribing! We'll notify you when we launch.",
        });
        reset();
      } else {
        toast({
          variant: "destructive",
          title: "Error",
          description: result.message,
        });
      }
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to subscribe. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <div className="flex flex-col space-y-2">
        <div className="relative">
          <Input
            type="email"
            placeholder="Enter your email"
            {...register('email')}
            className="pl-10"
          />
          <Mail className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-muted-foreground" />
        </div>
        {errors.email && (
          <p className="text-sm text-destructive">{errors.email.message}</p>
        )}
      </div>

      <div className="space-y-2">
        <p className="text-sm font-medium">Interests (optional)</p>
        <div className="grid grid-cols-2 gap-2">
          {interests.map((interest) => (
            <div key={interest.id} className="flex items-center space-x-2">
              <Checkbox
                id={interest.id}
                {...register('interests')}
                value={interest.id}
              />
              <label
                htmlFor={interest.id}
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {interest.label}
              </label>
            </div>
          ))}
        </div>
      </div>

      <Button type="submit" className="w-full" disabled={loading}>
        {loading ? 'Subscribing...' : 'Get Notified at Launch'}
      </Button>
    </form>
  );
}