import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { NewsletterForm } from './newsletter-form';

export function NewsletterCard() {
  return (
    <Card className="w-full max-w-md mx-auto">
      <CardHeader>
        <CardTitle className="text-2xl text-center">Get Early Access</CardTitle>
        <p className="text-center text-muted-foreground">
          Subscribe to be notified when we launch and receive exclusive early access.
        </p>
      </CardHeader>
      <CardContent>
        <NewsletterForm />
      </CardContent>
    </Card>
  );
}