import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { ArrowRight, Shield, Camera, Server } from 'lucide-react';
import { cn } from '@/lib/utils';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';

const slides = [
  {
    title: "Professional Home Surveillance",
    description: "Secure your home with enterprise-grade surveillance solutions.",
    image: "https://images.unsplash.com/photo-1557597774-9d273605dfa9?auto=format&fit=crop&q=80",
    cta: {
      text: "Explore Solutions",
      link: "/surveillance/cameras"
    },
    features: [
      "4K Resolution",
      "Night Vision",
      "Smart Detection"
    ]
  },
  {
    title: "Advanced NVR Systems",
    description: "Record and manage your security footage with powerful NVR solutions.",
    image: "https://images.unsplash.com/photo-1580982324076-d95230549339?auto=format&fit=crop&q=80",
    cta: {
      text: "View NVR Options",
      link: "/surveillance/nvr"
    },
    features: [
      "AI Analytics",
      "Cloud Backup",
      "Mobile Access"
    ]
  },
  {
    title: "Smart Integration",
    description: "Connect your surveillance system with home automation.",
    image: "https://images.unsplash.com/photo-1558002038-1055907df827?auto=format&fit=crop&q=80",
    cta: {
      text: "Learn More",
      link: "/surveillance/setup"
    },
    features: [
      "Home Automation",
      "Mobile Alerts",
      "Voice Control"
    ]
  }
];

export function HeroSlider() {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [Autoplay()]);

  useEffect(() => {
    if (!emblaApi) return;
    
    const onSelect = () => {
      setActiveIndex(emblaApi.selectedScrollSnap());
    };
    
    emblaApi.on('select', onSelect);
    return () => emblaApi.off('select', onSelect);
  }, [emblaApi]);

  return (
    <section className="relative bg-background overflow-hidden">
      <div ref={emblaRef} className="overflow-hidden">
        <div className="flex">
          {slides.map((slide, index) => (
            <div 
              key={index}
              className="flex-[0_0_100%] min-w-0 relative"
              role="group"
              aria-roledescription="slide"
            >
              <div className="container grid grid-cols-1 lg:grid-cols-2 gap-8 py-20">
                <div className="flex flex-col justify-center">
                  <h1 className="text-4xl md:text-5xl font-bold tracking-tight mb-6">
                    {slide.title}
                  </h1>
                  <p className="text-xl text-muted-foreground mb-8">
                    {slide.description}
                  </p>
                  <div className="flex flex-wrap gap-4 mb-8">
                    <Button 
                      size="lg"
                      onClick={() => navigate(slide.cta.link)}
                    >
                      {slide.cta.text}
                      <ArrowRight className="ml-2 h-4 w-4" />
                    </Button>
                  </div>
                  <div className="grid grid-cols-3 gap-4">
                    {slide.features.map((feature, i) => (
                      <div key={i} className="flex items-center space-x-2">
                        <Shield className="h-5 w-5 text-primary" />
                        <span className="text-sm">{feature}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="relative">
                  <div className="aspect-[4/3] rounded-lg overflow-hidden shadow-2xl">
                    <img
                      src={slide.image}
                      alt={slide.title}
                      className="object-cover w-full h-full"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => emblaApi?.scrollTo(index)}
            className={cn(
              "w-2 h-2 rounded-full transition-all duration-300",
              activeIndex === index 
                ? "bg-primary w-8" 
                : "bg-primary/30 hover:bg-primary/50"
            )}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
    </section>
  );
}