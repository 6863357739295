import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { HardDrive, Monitor, Book, Wrench, ArrowRight } from "lucide-react";
import { routes } from "@/lib/routes";

const quickLinks = [
  {
    title: "Hardware Solutions",
    description: "Find the perfect hardware for your needs",
    icon: HardDrive,
    href: routes.hardware.index
  },
  {
    title: "Software Guide",
    description: "Essential software for your server",
    icon: Monitor,
    href: routes.software.index
  },
  {
    title: "Beginner's Guide",
    description: "Start your home server journey",
    icon: Book,
    href: routes.guides.beginners
  },
  {
    title: "Troubleshooting",
    description: "Common issues and solutions",
    icon: Wrench,
    href: routes.guides.troubleshooting
  }
];

export function QuickLinks() {
  return (
    <section className="py-16 bg-muted/30">
      <div className="container">
        <h2 className="text-3xl font-bold tracking-tight mb-8 text-center">
          Quick Resources
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {quickLinks.map((link) => (
            <Link key={link.href} to={link.href}>
              <Card className="h-full hover:shadow-lg transition-shadow">
                <CardContent className="p-6">
                  <div className="flex flex-col items-center text-center">
                    <link.icon className="h-12 w-12 text-primary mb-4" />
                    <h3 className="text-lg font-semibold mb-2">{link.title}</h3>
                    <p className="text-sm text-muted-foreground mb-4">
                      {link.description}
                    </p>
                    <Button variant="ghost" size="sm" className="w-full group">
                      Explore
                      <ArrowRight className="ml-2 h-4 w-4 group-hover:translate-x-1 transition-transform" />
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}