import { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Progress } from '@/components/ui/progress';
import { cn } from '@/lib/utils';
import { type PollOption } from '@/lib/types';
import { trackPollVote } from '@/lib/analytics/gtag';

interface PollCardProps {
  id: string;
  question: string;
  options: PollOption[];
  onVote: (pollId: string, optionId: string) => Promise<void>;
  className?: string;
}

export function PollCard({ id, question, options, onVote, className }: PollCardProps) {
  const [voted, setVoted] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [voting, setVoting] = useState(false);
  
  const totalVotes = options.reduce((sum, option) => sum + option.votes, 0);

  const handleVote = async (optionId: string) => {
    if (!voted && !voting) {
      try {
        setVoting(true);
        await onVote(id, optionId);
        
        // Track the vote in GA4
        const selectedOptionText = options.find(opt => opt.id === optionId)?.text || '';
        trackPollVote(question, selectedOptionText);
        
        setSelectedOption(optionId);
        setVoted(true);
      } finally {
        setVoting(false);
      }
    }
  };

  return (
    <Card className={cn('w-full max-w-md mx-auto', className)}>
      <CardHeader>
        <CardTitle className="text-xl">{question}</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        {options.map((option) => {
          const percentage = totalVotes > 0 ? (option.votes / totalVotes) * 100 : 0;
          
          return (
            <div key={option.id} className="space-y-2">
              <Button
                variant={selectedOption === option.id ? "default" : "outline"}
                className="w-full justify-between"
                onClick={() => handleVote(option.id)}
                disabled={voted || voting}
              >
                <span>{option.text}</span>
                {voted && (
                  <span className="text-sm text-muted-foreground">
                    {percentage.toFixed(1)}% ({option.votes} votes)
                  </span>
                )}
              </Button>
              {voted && (
                <Progress 
                  value={percentage} 
                  className="h-2"
                />
              )}
            </div>
          );
        })}
        
        {voted && (
          <p className="text-sm text-center text-muted-foreground mt-4">
            Total votes: {totalVotes}
          </p>
        )}
      </CardContent>
    </Card>
  );
}