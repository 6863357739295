export const routes = {
  home: '/',
  hardware: {
    index: '/hardware',
    storage: {
      index: '/hardware/storage',
      hdd: '/hardware/storage/hdd',
      ssd: '/hardware/storage/ssd',
      m2: '/hardware/storage/m2',
      nas: '/hardware/storage/nas-drives'
    },
    formFactors: {
      index: '/hardware/form-factors',
      desktop: '/hardware/form-factors/desktop',
      rackmount: '/hardware/form-factors/rackmount',
      tower: '/hardware/form-factors/tower'
    },
    preBuilt: '/hardware/pre-built-servers',
    diyBuilds: '/hardware/diy-builds',
    components: '/hardware/components',
    network: '/hardware/network'
  },
  software: {
    index: '/software',
    os: '/software/operating-systems',
    mediaServers: '/software/media-servers',
    backup: '/software/backup',
    virtualization: '/software/virtualization'
  },
  guides: {
    index: '/guides',
    beginners: '/guides/beginners',
    advanced: '/guides/advanced',
    troubleshooting: '/guides/troubleshooting',
    bestPractices: '/guides/best-practices'
  },
  blog: '/blog',
  homelabbersCorner: '/homelabbers-corner',
  surveillance: {
    index: '/surveillance',
    cameras: '/surveillance/cameras',
    nvr: '/surveillance/nvr',
    setup: '/surveillance/setup',
    guides: '/surveillance/guides'
  }
} as const;