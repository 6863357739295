import { type SiteConfig } from './types';

export const homeServerConfig: SiteConfig = {
  name: 'BestHomeServers',
  description: 'Expert guidance on home servers, NAS solutions, and data management',
  url: 'https://besthomeservers.com',
  ogImage: 'https://besthomeservers.com/og.jpg',
  links: {
    twitter: 'https://twitter.com/besthomeservers',
    github: 'https://github.com/besthomeservers',
  },
};

export const navigationItems = {
  homeServer: [
    { title: 'Home', href: '/' },
    {
      title: 'Hardware',
      href: '/hardware',
      subItems: [
        {
          title: 'Form Factors',
          href: '/hardware/form-factors',
          subItems: [
            { title: 'Desktop Servers', href: '/hardware/form-factors/desktop' },
            { title: 'Rackmount Servers', href: '/hardware/form-factors/rackmount' },
            { title: 'Tower Servers', href: '/hardware/form-factors/tower' },
            { title: 'Mini Servers', href: '/hardware/form-factors/mini' }
          ]
        },
        {
          title: 'Storage Solutions',
          href: '/hardware/storage',
          subItems: [
            { title: 'Hard Drives', href: '/hardware/storage/hdd' },
            { title: 'SSDs', href: '/hardware/storage/ssd' },
            { title: 'M.2 Drives', href: '/hardware/storage/m2' },
            { title: 'NAS Drives', href: '/hardware/storage/nas-drives' },
            { title: 'RAID Arrays', href: '/hardware/storage/raid' }
          ]
        }
      ]
    },
    {
      title: 'Software',
      href: '/software',
      subItems: [
        { title: 'Operating Systems', href: '/software/os' },
        { title: 'Media Servers', href: '/software/media-servers' },
        { title: 'Backup Solutions', href: '/software/backup' },
        { title: 'Virtualization', href: '/software/virtualization' }
      ]
    },
    {
      title: 'Guides',
      href: '/guides',
      subItems: [
        { title: 'Getting Started', href: '/guides/getting-started' },
        { title: 'Advanced Setup', href: '/guides/advanced' },
        { title: 'Troubleshooting', href: '/guides/troubleshooting' },
        { title: 'Best Practices', href: '/guides/best-practices' }
      ]
    },
    { title: 'Blog', href: '/blog' }
  ]
};

export const featuredBuilds = [
  {
    title: 'Home Media Server',
    description: 'Perfect for streaming and media management',
    image: 'https://images.unsplash.com/photo-1589652717521-10c0d092dea9?auto=format&fit=crop&q=80',
    specs: {
      cpu: 'Intel Core i5',
      ram: '32GB',
      storage: '20TB',
      os: 'TrueNAS'
    }
  },
  {
    title: 'Developer Workstation',
    description: 'Ideal for running multiple VMs and containers',
    image: 'https://images.unsplash.com/photo-1587202372634-32705e3bf49c?auto=format&fit=crop&q=80',
    specs: {
      cpu: 'AMD Ryzen 9',
      ram: '64GB',
      storage: '4TB NVMe',
      os: 'Proxmox'
    }
  },
  {
    title: 'Home Office NAS',
    description: 'Reliable storage and backup solution',
    image: 'https://images.unsplash.com/photo-1601386222674-715d1c72f518?auto=format&fit=crop&q=80',
    specs: {
      cpu: 'Intel Xeon',
      ram: '16GB ECC',
      storage: '40TB',
      os: 'Unraid'
    }
  }
];

export const targetAudiences = [
  {
    id: 'homelab',
    title: 'Home Lab Enthusiasts',
    description: 'For tech enthusiasts building advanced home setups',
    interests: ['Virtualization', 'Networking', 'Custom Builds']
  },
  {
    id: 'soho',
    title: 'Small Office/Home Office',
    description: 'Professional solutions for remote work and small businesses',
    interests: ['File Sharing', 'Backup', 'Remote Access']
  },
  {
    id: 'homeowner',
    title: 'Home Users',
    description: 'Simple and reliable solutions for home use',
    interests: ['Media Streaming', 'Storage', 'Easy Management']
  }
];