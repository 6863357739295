import { get, set } from 'idb-keyval';
import { v4 as uuidv4 } from 'uuid';
import type { Poll } from '@/lib/types';
import type { ApiResponse } from './types';

const POLLS_STORE_KEY = 'polls';

const DEFAULT_POLLS: Poll[] = [
  {
    id: uuidv4(),
    question: 'What type of home server do you prefer?',
    options: [
      { id: uuidv4(), text: 'Pre-built NAS', votes: 42 },
      { id: uuidv4(), text: 'Custom Build', votes: 78 },
      { id: uuidv4(), text: 'Cloud Solution', votes: 23 }
    ],
    createdAt: new Date().toISOString(),
    totalVotes: 143
  },
  {
    id: uuidv4(),
    question: 'Which operating system do you use?',
    options: [
      { id: uuidv4(), text: 'TrueNAS', votes: 56 },
      { id: uuidv4(), text: 'Unraid', votes: 89 },
      { id: uuidv4(), text: 'Proxmox', votes: 67 },
      { id: uuidv4(), text: 'Other', votes: 34 }
    ],
    createdAt: new Date().toISOString(),
    totalVotes: 246
  }
];

async function initializePolls(): Promise<Poll[]> {
  try {
    const existingPolls = await get(POLLS_STORE_KEY);
    if (!existingPolls) {
      await set(POLLS_STORE_KEY, DEFAULT_POLLS);
      return DEFAULT_POLLS;
    }
    return existingPolls;
  } catch (error) {
    console.error('Error initializing polls:', error);
    return DEFAULT_POLLS;
  }
}

export async function fetchPolls(): Promise<ApiResponse<Poll[]>> {
  try {
    const polls = await initializePolls();
    return { data: polls, error: null };
  } catch (error) {
    console.error('Error fetching polls:', error);
    return {
      data: null,
      error: {
        message: 'Failed to fetch polls',
        status: 500
      }
    };
  }
}

export async function submitVote(
  pollId: string,
  optionId: string
): Promise<ApiResponse<Poll>> {
  try {
    const polls = await get(POLLS_STORE_KEY);
    const updatedPolls = polls.map((poll: Poll) => {
      if (poll.id === pollId) {
        const updatedOptions = poll.options.map(option => {
          if (option.id === optionId) {
            return { ...option, votes: option.votes + 1 };
          }
          return option;
        });
        return {
          ...poll,
          options: updatedOptions,
          totalVotes: poll.totalVotes + 1
        };
      }
      return poll;
    });

    await set(POLLS_STORE_KEY, updatedPolls);
    const updatedPoll = updatedPolls.find((p: Poll) => p.id === pollId);

    return { data: updatedPoll, error: null };
  } catch (error) {
    console.error('Error submitting vote:', error);
    return {
      data: null,
      error: {
        message: 'Failed to submit vote',
        status: 500
      }
    };
  }
}