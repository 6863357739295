import { Server } from 'lucide-react';
import { navigationItems, homeServerConfig } from '@/lib/constants';

export function Footer() {
  return (
    <footer className="border-t bg-muted/50">
      <div className="container py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <div className="flex items-center space-x-2 mb-4">
              <Server className="h-6 w-6" />
              <span className="font-bold">{homeServerConfig.name}</span>
            </div>
            <p className="text-sm text-muted-foreground">
              {homeServerConfig.description}
            </p>
          </div>
          
          {navigationItems.homeServer.slice(1).map((section) => (
            <div key={section.href}>
              <h3 className="font-semibold mb-4">{section.title}</h3>
              <ul className="space-y-2">
                {section.subItems?.map((item) => (
                  <li key={item.href}>
                    <a
                      href={item.href}
                      className="text-sm text-muted-foreground hover:text-foreground transition-colors"
                    >
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        
        <div className="mt-8 pt-8 border-t">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <p className="text-sm text-muted-foreground">
              © {new Date().getFullYear()} {homeServerConfig.name}. All rights reserved.
            </p>
            <div className="flex items-center space-x-4">
              <a
                href={homeServerConfig.links.twitter}
                className="text-sm text-muted-foreground hover:text-foreground transition-colors"
              >
                Twitter
              </a>
              <a
                href={homeServerConfig.links.github}
                className="text-sm text-muted-foreground hover:text-foreground transition-colors"
              >
                GitHub
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}