import { useState, useEffect } from 'react';
import { PollCard } from './poll-card';
import { fetchPolls, submitVote } from '@/lib/api/polls';
import type { Poll } from '@/lib/types';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Skeleton } from '@/components/ui/skeleton';

export function PollList() {
  const [polls, setPolls] = useState<Poll[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadPolls();
  }, []);

  async function loadPolls() {
    try {
      setLoading(true);
      setError(null);
      const response = await fetchPolls();
      
      if (response.error) {
        throw new Error(response.error.message);
      }

      if (response.data) {
        setPolls(response.data);
      }
    } catch (err) {
      setError('Failed to load polls. Please try again later.');
      console.error('Error loading polls:', err);
    } finally {
      setLoading(false);
    }
  }

  const handleVote = async (pollId: string, optionId: string) => {
    try {
      const response = await submitVote(pollId, optionId);
      
      if (response.error) {
        throw new Error(response.error.message);
      }

      if (response.data) {
        setPolls((currentPolls) =>
          currentPolls.map((poll) =>
            poll.id === pollId ? response.data : poll
          )
        );
      }
    } catch (err) {
      setError('Failed to submit vote. Please try again.');
      console.error('Error submitting vote:', err);
    }
  };

  if (loading) {
    return (
      <div className="space-y-8">
        {[1, 2].map((index) => (
          <div key={index} className="w-full max-w-md mx-auto">
            <Skeleton className="h-48 w-full rounded-lg" />
          </div>
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="space-y-8">
      {polls.map((poll) => (
        <PollCard
          key={poll.id}
          id={poll.id}
          question={poll.question}
          options={poll.options}
          onVote={handleVote}
        />
      ))}
    </div>
  );
}