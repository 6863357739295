import { Card } from '@/components/ui/card';
import { useLaunchTimer } from '@/lib/hooks/use-launch-timer';

interface CountdownTimerProps {
  targetDate: Date;
}

export function CountdownTimer({ targetDate }: CountdownTimerProps) {
  const timeLeft = useLaunchTimer(targetDate);

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 max-w-2xl mx-auto">
      {Object.entries(timeLeft).map(([unit, value]) => (
        <Card key={unit} className="p-4 text-center">
          <div className="text-4xl font-bold text-primary mb-2">
            {value.toString().padStart(2, '0')}
          </div>
          <div className="text-sm text-muted-foreground capitalize">
            {unit}
          </div>
        </Card>
      ))}
    </div>
  );
}