import { Link } from 'react-router-dom';
import { navigationItems } from '@/lib/constants';
import { cn } from '@/lib/utils';

interface MobileNavProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export function MobileNav({ isOpen, setIsOpen }: MobileNavProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 top-16 z-50 grid h-[calc(100vh-4rem)] grid-flow-row auto-rows-max overflow-auto p-6 pb-32 shadow-md animate-in slide-in-from-bottom-80 md:hidden">
      <div className="relative z-20 grid gap-6 rounded-md bg-popover p-4 text-popover-foreground shadow-md">
        <nav className="grid grid-flow-row auto-rows-max text-sm">
          {navigationItems.homeServer.map((item) => (
            <div key={item.href} className="py-2">
              <Link
                to={item.href}
                className="font-medium transition-colors hover:text-primary"
                onClick={() => setIsOpen(false)}
              >
                {item.title}
              </Link>
              {item.subItems && (
                <div className="ml-4 mt-2 space-y-2">
                  {item.subItems.map((subItem) => (
                    <div key={subItem.href}>
                      <Link
                        to={subItem.href}
                        className="text-muted-foreground transition-colors hover:text-primary"
                        onClick={() => setIsOpen(false)}
                      >
                        {subItem.title}
                      </Link>
                      {subItem.subItems && (
                        <div className="ml-4 mt-1 space-y-1">
                          {subItem.subItems.map((childItem) => (
                            <Link
                              key={childItem.href}
                              to={childItem.href}
                              className="block text-sm text-muted-foreground transition-colors hover:text-primary"
                              onClick={() => setIsOpen(false)}
                            >
                              {childItem.title}
                            </Link>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
}