import { PollList } from '@/components/polls/poll-list';

export function CommunityPolls() {
  return (
    <section className="py-16 bg-muted/30">
      <div className="container">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold tracking-tight mb-4">
            Community Polls
          </h2>
          <p className="text-xl text-muted-foreground max-w-2xl mx-auto">
            Share your opinion and see what other home server enthusiasts think about various topics.
          </p>
        </div>
        <PollList />
      </div>
    </section>
  );
}