import { ReactNode } from 'react';

interface LayoutRootProps {
  children: ReactNode;
}

export function LayoutRoot({ children }: LayoutRootProps) {
  return (
    <div className="min-h-screen flex flex-col bg-background text-foreground">
      {children}
    </div>
  );
}